import React from 'react'
import styled from 'styled-components'

// Elements
import Plaatjie from '@ubo/plaatjie'
import ParseContent from 'components/shared/ParseContent'

const StyledUsps = styled.div`
  background-color: ${({ theme }) => theme.color.primary};
`

const UspIcon = styled(Plaatjie)`
  @media (min-width: 992px) {
    width: 75px;
    height: 75px;
  }

  @media (max-width: 991px) {
    width: 50px;
    height: 50px;
  }
`

const UspTitle = styled.h3`
  color: ${({ theme }) => theme.color.dark};
  font-weight: ${({ theme }) => theme.font.weight.bold};
  max-width: 175px;
  text-align: center;

  @media (min-width: 992px) {
    font-size: ${({ theme }) => theme.font.size.large};
  }

  @media (max-width: 991px) {
    font-size: ${({ theme }) => theme.font.size.medium};
  }
`

interface UspsProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Usps
}

const Usps: React.FC<UspsProps> = ({ fields }) => (
  <section>
    <StyledUsps className="my-lg-5 py-5">
      <div className="container">
        <ParseContent content={fields.description} />
        <div className="row mt-5 pt-lg-5 justify-content-center">
          {fields.items?.map((edge, index: number) => (
            <div
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              className="col-4 col-lg-3 px-3 text-center mt-3 mt-lg-0"
            >
              <UspIcon image={edge?.icon} alt={edge?.title || 'VT Tuinen'} />
              <UspTitle className="mx-auto mt-4 mb-0">{edge?.title}</UspTitle>
            </div>
          ))}
        </div>
      </div>
    </StyledUsps>
  </section>
)

export default Usps
